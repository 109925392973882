// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contatto-js": () => import("./../../src/pages/contatto.js" /* webpackChunkName: "component---src-pages-contatto-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-album-js": () => import("./../../src/templates/album.js" /* webpackChunkName: "component---src-templates-album-js" */)
}

